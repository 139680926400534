@import "node_modules/bootstrap/scss/bootstrap";

#app > div > .navbar {
  margin-bottom: 25px;
}
#app > div > .container {
  max-width: 800px;
}

.form-control {
  white-space: nowrap;
}

.table-sm-sm {
  .form-control {
    &[readonly="true"] {
      padding-top: 0.375rem;
    }
  }
}

.form-control.is-invalid-warning {
  @extend .is-invalid;
  border-color: $warning;
  background-image: escape-svg(
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$warning}' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='#{$warning}' stroke='none'/%3e%3c/svg%3e")
  );
  &:focus {
    border-color: $warning;
    box-shadow: none;
  }
}

.form-control:focus {
  box-shadow: none;
}

@include media-breakpoint-down(sm) {
  .table-sm-sm {
    th,
    td {
      padding: $table-cell-padding-sm;
    }
    .form-control {
      padding: 0.175rem 0.25rem;
    }
  }
}

.nav-tabs .nav-link {
  color: $gray-700;
  font-weight: $font-weight-light;

  &.active {
    font-weight: $font-weight-normal;
  }
}

.accordion .btn-link {
  color: $gray-700;
  text-decoration: none;
  font-weight: $font-weight-normal;
}
